import dynamic from 'next/dynamic';

const HtmlComponent = dynamic(() => import('components/Html/Html'));

const HeaderBar = ({ headerSettings } : any) => {
    const bannerBgColor = headerSettings?.backgroundColor ?? '';
    const bannerBgType = headerSettings?.backgroundType ?? '';
    let bannerBgCls = "default";
    const headerWide = headerSettings?.headerWide ?? false;
    const htmlData = headerSettings?.headerText ?? '';

    switch (bannerBgType)
    {
    case "solid":
        bannerBgCls = "topbar-dark";
        break;
    case "white":
        bannerBgCls = "topbar-light";
        break;
    case "custom":
        bannerBgCls = "topbar-custom";
        break;
    default:
        bannerBgCls = "topbar-dark";
        break;
    }

    return(
        <>
            <style>{`.topbar-custom{background-color:${bannerBgColor}}`}</style>
            <div className={`topbar ${bannerBgCls}`}>
                <div className={headerWide ? "container-fluid" : "container"}>
                    <HtmlComponent htmlValue={htmlData} />
                </div>
            </div>
        </>
    );
};

export default HeaderBar;